import Card from 'components/molecules/card'
import { createClient } from 'contentful'
import { getCurrentLang } from 'helpers/locale'
import { useResourceTextData } from 'hooks/useResourceTextData'
import { SearchResultsMsg } from 'pages/community/search-results'
import React, { useState } from 'react'
import styled, { css, CSSProp } from 'styled-components'
import { ContentfulArticle } from 'types/graphql-types'

export const ArticlesGrid = styled.div`
  ${({ theme }): CSSProp => css`
    display: grid;
    grid-column-gap: 2rem;
    grid-row-gap: 8rem;
    margin-bottom: ${theme.padding.screenDefault};
    grid-template-columns: repeat(2, 1fr);

    @media only screen and ${theme.breakpoints.fromMediumScreen} {
      grid-template-columns: repeat(6, 1fr);
    }

    @media only screen and ${theme.breakpoints.fromNormalScreen} {
      grid-template-columns: repeat(12, 1fr);
    }
  `}
`

export const ArticleGridItem = styled.div<{ uniform?: boolean }>`
  ${({ theme, uniform }): CSSProp => css`
    &:nth-child(7n + 7) {
      grid-column: span ${uniform ? 1 : 2};
    }

    @media only screen and ${theme.breakpoints.fromMediumScreen} {
      grid-column: span 3;

      &:nth-child(7n + 5),
      &:nth-child(7n + 6),
      &:nth-child(7n + 7) {
        grid-column: span 2;
      }
    }

    @media only screen and ${theme.breakpoints.fromNormalScreen} {
      &:nth-child(7n + 1),
      &:nth-child(7n + 2),
      &:nth-child(7n + 3),
      &:nth-child(7n + 4) {
        grid-column: span 3;
      }

      &:nth-child(7n + 5),
      &:nth-child(7n + 6),
      &:nth-child(7n + 7) {
        grid-column: span ${uniform ? 3 : 4};
      }
    }
  `}
`

const LoadMore = styled.div`
  ${({ theme }): CSSProp => css`
    margin-bottom: 6rem;
    text-decoration: underline;
    cursor: pointer;
    text-transform: uppercase;
    font-size: ${theme.font.fontSizeSmall};
    text-align: center;
  `}
`

const ArticlesTagList = ({
  articles,
  more,
  tagSlug,
  skip = 8,
  uniform,
  total,
}: {
  articles: ContentfulArticle[]
  more?: boolean
  tagSlug?: string
  skip?: number
  uniform?: boolean
  total: number
}): JSX.Element => {
  const communityTextResultFound = useResourceTextData(
    'community.resultFound',
    'result found'
  )
  const communityTextResultsFound = useResourceTextData(
    'community.resultsFound',
    'results found'
  )

  const locale = getCurrentLang()

  const [isLoading, setIsLoading] = useState(false)
  const [articlesArray, setArticlesArray] = useState(articles)
  const [articlesQueryInfo, setArticlesQueryInfo] = useState({
    limit: 8,
    skip,
    more: true,
  })

  const getMoreArticles = async (): Promise<any> => {
    setIsLoading(true)
    const params = {
      // eslint-disable-next-line @typescript-eslint/camelcase
      content_type: 'article',
      'fields.tags.sys.id': tagSlug,
      skip: articlesQueryInfo.skip.toString(),
      limit: articlesQueryInfo.limit.toString(),
      locale: locale,
    }

    const client = createClient({
      accessToken: process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN,
      space: process.env.GATSBY_CONTENTFUL_SPACE_ID,
    })
    const response = await client.getEntries(params)

    if (response.items.length > 0) {
      const getLinkedCategory = categoryId => {
        const entry = response.includes.Entry.filter(item => {
          return item.sys.id === categoryId
        })

        return entry[0].fields
      }

      const getLinkedAsset = assetId => {
        const entry = response.includes.Asset.filter(item => {
          return item.sys.id === assetId
        })

        return {
          title: entry[0].fields.title,
          url: entry[0].fields.file.url,
        }
      }

      const newArticles = response.items.map(newArticle => {
        const category = getLinkedCategory(newArticle.fields.category.sys.id)
        const heroImage = getLinkedAsset(newArticle.fields.heroImage.sys.id)

        return {
          id: newArticle.sys.id,
          title: newArticle.fields.title,
          slug: newArticle.fields.slug,
          category: category,
          heroImage: {
            title: heroImage.title,
            fluid: {
              src: heroImage.url + '?w=450&h=340',
            },
          },
        }
      })
      setArticlesArray(articlesArray.concat(newArticles))

      setArticlesQueryInfo({
        ...articlesQueryInfo,
        skip: articlesQueryInfo.limit + articlesQueryInfo.skip,
        more: articlesQueryInfo.limit + response.skip <= response.total,
      })
    } else {
      console.error('There was an error retrieving the articles.')
    }

    setIsLoading(false)
  }

  return (
    <>
      <SearchResultsMsg>
        <strong>{total}</strong>{' '}
        {total === 1 ? communityTextResultFound : communityTextResultsFound}
      </SearchResultsMsg>
      <ArticlesGrid>
        {articlesArray.map(article => {
          return (
            <ArticleGridItem key={article.id} uniform={uniform}>
              <Card
                title={article.title}
                slug={`/community/${article.slug}/`}
                subTitle={{
                  title: article.category?.title,
                  slug: article.category?.slug
                    ? `/community/category/${article.category.slug}/`
                    : '',
                }}
                image={article.heroImage}
              />
            </ArticleGridItem>
          )
        })}
      </ArticlesGrid>
      {more && articlesQueryInfo.more && (
        <LoadMore onClick={getMoreArticles}>
          {isLoading ? 'Loading...' : 'Load more articles'}
        </LoadMore>
      )}
    </>
  )
}

export default ArticlesTagList
